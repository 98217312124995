:root {
  --blue: #017bce;
  --dark-blue-1: #074f8a;
  --dark-blue-2: #07436c;
  --transparent-blue: rgba(1, 123, 206, 0.3);
  --semi-opaque-blue: rgba(1, 123, 206, 0.85);

  --light-purple: #486ffa;
  --transparent-purple: rgba(72, 111, 250, 0.3);
  --semi-opaque-purple: rgba(72, 111, 250, 0.85);
  --purple: #150866;

  --white: #fff;
  --black: #000;

  --green: #50b83c;
  --light-green: #e9fee5;

  --red: #de3618;
  --light-red: #ffe6e0;

  --orange: #f49342;
  --light-orange: #fff7d4;

  --primary: var(--blue);
  --transparent-primary: var(--transparent-blue);
  --semi-opaque-primary: var(--semi-opaque-blue);

  --highlight: #fccc63;

  --heading: var(--dark-blue-1);
  --sub-heading: var(--dark-blue-2);

  --font-family: 'SF Pro Text', sans-serif;

  --container-max-width: 1288px;
  --container-padding: 32px;
}

@media screen and (max-width: 900px) {
  :root {
    --container-padding: 1rem;
  }
}
