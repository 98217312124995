.header {
  height: 400px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.headerBg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.headerText {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerTextHeader {
  color: #074f8a;
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 140%;
  margin-bottom: 40px;
  text-align: center;
}

.headerTextSub {
  width: 80%;
  max-width: 418px;
  color: #074f8a;
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 133%;
  text-align: center;
}

.main {
  background-color: #f9fafb;
}

.mainGrid {
  padding: 80px var(--container-padding) 100px;
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  grid-column-gap: 25%;
  align-items: center;
}

.formHeader {
  color: #074f8a;
  font-family: var(--font-family);
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 46px;
  margin-bottom: 20px;
}

.formSubtext {
  max-width: 430px;
  opacity: 0.8;
  color: #07436c;
  font-family: var(--font-family);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 50px;
}

.formInputs {
  display: grid;
  grid-row-gap: 20px;
}

.formButton {
  width: fit-content;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 70px 85px 40px 40px;
  height: 430px;
  width: 100%;
  background-color: #007bd5;
}

.contactDetail {
  display: flex;
  align-items: flex-start;
  color: #e9f5fb;
  font-family: var(--font-family);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}

.contactDetail + .contactDetail {
  margin-top: 30px;
}

.contactDetailCenter {
  align-items: center;
}

.contactDetailLocationIcon {
  width: 20px;
  fill: white;
  margin-right: 10px;
  padding-top: 5px;
  flex-shrink: 0;
}

.contactDetailEmailIcon {
  width: 20px;
  fill: white;
  margin-right: 10px;
  flex-shrink: 0;
}

.contactDetailSocialMediaLinks {
  display: grid;
  grid-template-columns: repeat(3, 40px);
  grid-template-rows: 40px;
  grid-column-gap: 30px;
}

.contactDetailSocialMediaLink {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(7, 79, 138, 0.56);
  transition: 300ms;
  position: relative;
  border-radius: 50%;
}

.contactDetailSocialMediaLink:hover {
  background-color: rgba(255, 255, 255, 0.56);
}

.contactDetailSocialMediaLinkIcon {
  width: 40%;
  position: absolute;
  opacity: 1;
  transition: 300ms;
}

.contactDetailSocialMediaLink:not(.isFilled) {
  stroke: white;
  fill: none;
}

.contactDetailSocialMediaLinkIcon.isFilled,
.contactDetailSocialMediaLink:hover .contactDetailSocialMediaLinkIcon {
  opacity: 0;
}

.contactDetailSocialMediaLink:hover .contactDetailSocialMediaLinkIcon.isFilled {
  opacity: 1;
}

@media (max-width: 900px) {
  .mainGrid {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    padding: 40px var(--container-padding);
  }
  .headerText {
    padding-top: 30px;
    justify-content: flex-start;
  }
  .headerTextHeader {
    margin-bottom: 30px;
    font-size: 32px;
  }
  .headerTextSub {
    font-size: 16px;
  }
}
