.chatWindowWrapper {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 20;
}

:global(.bot-only) .chatWindowWrapper {
  bottom: 0;
  right: 0;
}

.chatWindow {
  --window-enter-duration: 0.7s;
  --child-enter-duration: 0.3s;
  width: 100%;
  min-width: 370px;
  max-width: 370px;
  height: 70vh;
  max-height: 100%;
  border-radius: 8px;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.15);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  /* position: relative; */
  overflow: hidden;
  animation: window-enter var(--window-enter-duration) ease-in both;
  transform-origin: bottom right;
  background-color: var(--white);
  pointer-events: all;
  z-index: 100;
}

.chatWindowHide {
  animation: window-leave var(--window-enter-duration) ease-in both;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  pointer-events: none;
}

@keyframes window-enter {
  0% {
    transform: scaleX(0) scaleY(0.05);
    opacity: 0;
  }
  30% {
    transform: scaleX(1) scaleY(0.05);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes window-leave {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  70% {
    transform: scaleX(1) scaleY(0.05);
  }
  100% {
    transform: scaleX(0) scaleY(0.05);
    opacity: 0;
  }
}

.closeButton {
  --size: 60px;
  /*position: absolute;*/
  /*top: calc(-1 * (var(--size) / 2));*/
  /*left: calc(-1 * (var(--size) / 2));*/
  margin: 30px 0 0 auto;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 50%;
  /*border: 1px solid var();*/
  border: none;
  color: var(--white);
  cursor: pointer;
  box-shadow: 3px 3px 28px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  pointer-events: all;
  z-index: 50;
}

.closeButtonEmailIcon {
  display: none;
}

.closeButton svg {
  width: 14px;
  height: 14px;
}

.closeButton .closeButtonEmailIcon {
  width: 60px;
  height: 60px;
}

.closeButtonHide svg {
  display: none;
}

.closeButtonHide .closeButtonEmailIcon {
  display: flex;
}

.closeButtonMobile {
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: auto;
  color: white;
  display: none;
  cursor: pointer;
}

.closeButtonMobile svg {
  width: 14px;
  height: 14px;
}

.topBanner {
  /* height: 20%; */
  background-color: var(--primary);
  padding: 20px 25px 23px;
  display: flex;
  align-items: center;
  background-image: url('../../assets/images/banner-background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  animation: banner-enter var(--child-enter-duration) ease-out both;
  animation-delay: var(--window-enter-duration);
  transform: translateY(-100%);
}

.chatWindowHide .topBanner {
  animation: banner-leave var(--child-enter-duration) ease-out both;
}

@keyframes banner-enter {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes banner-leave {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.topBannerIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.topBannerTitle {
  font-size: 16px;
  line-height: 17px;
  font-weight: normal;
  letter-spacing: -0.2px;
  color: var(--white);
}

.topBannerBotStatus {
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */

  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.7);
}

.chatArea {
  flex: 1;
  background: linear-gradient(180deg, #fffdfb 12.06%, #f8f7f7 90.25%);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem;
  position: relative;
  opacity: 0;
  animation: enter-fade var(--child-enter-duration) ease-out both;
  animation-delay: var(--window-enter-duration);
}

.chatWindowHide .chatArea {
  animation: leave-fade var(--child-enter-duration) ease-out both;
  animation-delay: 0s;
}

@keyframes enter-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes leave-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.inputBox {
  padding: 20px 25px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: enter-fade var(--child-enter-duration) ease-out both;
  animation-delay: var(--window-enter-duration);
}

.inputBoxCollapsed {
  padding: 0;
}

.chatWindowHide .inputBox {
  animation: leave-fade var(--child-enter-duration) ease-out both;
  animation-delay: 0s;
}

.chatInputWrapper {
  display: flex;
}

.chatInput {
  border: none;
  background-color: var(--transparent);
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.7px;
  flex: 1;
  color: #6e7a89;
}

.chatInput::placeholder {
  opacity: 1;
}

.sendButton {
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.36;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.sendButton:disabled {
  color: #c8c8c8;
}

.chatInput:focus {
  outline: none;
}

.messageRow {
  display: flex;
  align-items: flex-end;
}

.messageRowSent {
  justify-content: flex-end;
}

.messageRowSpaced {
  margin-bottom: 25px;
}

.messageRowCollapsed {
  margin-bottom: 4px;
}

.messageAvatarWrapper {
  --size: 28px;
  width: var(--size);
  height: var(--size);
  margin-right: 13px;
  box-sizing: content-box;
}

.messageRowSpaced .messageAvatarWrapper {
  margin-bottom: 20px;
}

.messageAvatar {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: block;
  object-fit: contain;
}

.messageContent {
  display: flex;
  flex-direction: column;
}

.messageBox {
  border-radius: 8px;
  padding: 1rem;
  background-color: #eff3f6;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #292f35;
  max-width: 265px;
  min-width: 150px;
  font-family: var(--font-family);
}

.riskLevelBox {
  display: flex;
  align-items: center;
}

.riskLevelIcon {
  width: 32px;
  margin-right: 10px;
}

.lowRisk {
  background-color: var(--light-green);
  color: var(--green);
}

.mediumRisk {
  background-color: var(--light-orange);
  color: var(--orange);
}

.highRisk {
  background-color: var(--light-red);
  color: var(--red);
}

.messageBox a {
  color: var(--primary);
}

.typingIndicatorText {
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #a8b6c2;
}

.messageRowSent .messageBox {
  background-color: var(--primary);
  color: var(--white);
}

.messageMeta {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
}

.messageRowSent .messageMeta {
  justify-content: flex-end;
}

.messageMetaItem {
  font-size: 13px;
  line-height: 15px;
  color: #a8b6c2;
}

.messageMetaItemEmphasized {
  color: var(--primary);
}

@media screen and (max-width: 900px) {
  .chatWindowWrapper {
    display: block;
    width: 100%;
    position: fixed;
    height: 100%;
    bottom: 0;
    right: 0;
  }

  .chatWindow {
    min-width: unset;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .closeButton {
    position: absolute;
    bottom: 0px;
    right: 0px;
    opacity: 0;
    pointer-events: none;
    transition: 300ms;
  }

  .closeButtonHide {
    opacity: 1;
    pointer-events: auto;
  }

  .closeButtonMobile {
    display: block;
  }

  .topBanner {
    border-radius: 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: var(--child-enter-duration) ease-out;
  transform: scale(1.2);
}

.overlayVisible {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: scale(1);
}

.spinner {
  width: 48px;
  height: 48px;
  border: 4px solid transparent;
  border-top-color: var(--primary);
  animation: spin 0.5s linear infinite;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.errorIcon {
  width: 32px;
  height: 32px;
  color: red;
  margin-bottom: 16px;
}

.errorText {
  color: #6e7a89;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1.36;
  text-align: center;
  max-width: 80%;
}

.chipsInput {
  display: flex;
  flex-direction: column;
}

.chipList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.inlineChipList {
  padding: 1rem;
  flex-direction: column;
}

.chip {
  background: var(--white);
  border: 1px solid var(--primary);
  border-radius: 74px;
  padding: 10px 15px;
  margin-right: 10px;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.3px;
  min-width: 66px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
  transition: 0.15s ease-out;
  font-family: var(--font-family);
}

.chipSelected,
.chipButton {
  background: var(--primary);
  color: var(--white);
}

.chipButton:focus {
  outline: none;
}

.chip:hover,
.chip:focus-within,
.chipButton:focus {
  background-color: var(--transparent-primary);
  color: var(--black);
}

.chipSelected:hover,
.chipSelected:focus-within {
  background-color: var(--semi-opaque-primary);
  color: var(--white);
}

.chip input[type='checkbox'] {
  width: 0;
  height: 0;
  overflow: hidden;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
}

.multiChoiceSendButton {
  margin-left: auto;
}
