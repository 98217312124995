@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: normal;
  src: url("SFProText-Regular.woff") format("woff"),
    url("SFProText-Regular.woff2") format("woff2"),
    url("SFProText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: italic;
  font-weight: normal;
  src: url("SFProText-RegularItalic.woff") format("woff"),
    url("SFProText-RegularItalic.woff2") format("woff2"),
    url("SFProText-RegularItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: bold;
  src: url("SFProText-Bold.woff") format("woff"),
    url("SFProText-Bold.woff2") format("woff2"),
    url("SFProText-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  src: url("SFProText-SemiBold.woff") format("woff"),
    url("SFProText-SemiBold.woff2") format("woff2"),
    url("SFProText-SemiBold.ttf") format("truetype");
}
