@import './styles/fonts.css';
@import './styles/variables.css';

html {
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: #263238;
  background-image: url('./assets/images/page-background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
