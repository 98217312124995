.chatWithBotCard {
  background-color: var(--white);
  background-image: url('../../../assets/images/chat-card-background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 35px 36px -51px rgba(0, 118, 197, 0.3);
  padding: 60px 16px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  max-width: 780px;
  color: var(--primary);
  margin-bottom: 35px;
}
