.footer {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10%;
  flex-wrap: wrap;
  background-color: #074f8a;
}

.footerLinks {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 45px;
}

.footerLink {
  color: #ffffff;
  font-family: var(--font-family);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}

.footerLink:hover {
  text-decoration: underline;
}

.footerIcons {
  display: grid;
  grid-template-columns: repeat(3, 40px);
  grid-column-gap: 30px;
  align-items: center;
}

.footerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  height: 40px;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  transition: 300ms;
}

.footerIcon:hover {
  background-color: rgba(255, 255, 255, 0.56);
}

.footerIconSvg {
  width: 16px;
  position: absolute;
  transition: 300ms;
}

.footerIconSvg:not(.footerIconSvgHoverShow) {
  stroke: white;
  fill: none;
  opacity: 1;
}

.footerIcon:hover .footerIconSvg {
  opacity: 0;
}

.footerIconSvgHoverShow {
  opacity: 0;
}

.footerIcon:hover .footerIconSvgHoverShow {
  opacity: 1;
}

@media (max-width: 620px) {
  .footer {
    justify-content: center;
  }
  .footerLinks {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    padding-top: 10px;
  }
  .footerLink:last-of-type {
    grid-column: 1/3;
    text-align: center;
  }
}
