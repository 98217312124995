body {
  background-color: #f9fafb;
}

.showcase {
  /*height: 100%;*/
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.showcaseBg {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
}

.showcaseHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.showcaseText {
  width: 60%;
  color: #074f8a;
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: bold;
  line-height: 60px;
  margin-top: 70px;
  margin-bottom: 20px;
  text-align: center;
}

.showcaseSubText {
  width: 57%;
  max-width: 60%;
  color: var(--sub-heading);
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 19px;
  letter-spacing: 0.3px;
  line-height: 32px;
  text-align: center;
}

.getTheApp {
  padding: 80px 10% 120px;
  background-color: #f4f6f8;
}

.sectionWrapper {
  display: grid;
  /*
    To prevent grid blowout
    Ref: https://css-tricks.com/preventing-a-grid-blowout/
  */
  grid-template-columns: repeat(2, minmax(0, 40%)) minmax(0, 20%);
  column-gap: 10px;
  padding: 0 15px 0 15px;
  margin-top: -12%;
}

.placeholderGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
}

.leftPlaceholder {
  max-width: 100%;
  display: block;
}

.leftPlaceholderImage,
.rightPlaceholderImage {
  height: 240px;
  max-width: 245px;
  border-radius: 10px;
}

.rightPlaceholder {
  display: grid;
  row-gap: 10px;
}

.leftImg,
.rightImg {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.centerImg {
  object-fit: cover;
  height: 495px;
  width: 500px;
  border-radius: 10px;
}

.aboutUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectionGrid {
  display: grid;
  grid-template-columns: 45% 50%;
  width: 100%;
  align-items: center;
  column-gap: 70px;
  height: auto;
  padding: 30px;
}

.gridText {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: var(--font-family);
}

.gridTextAppName {
  color: #51b83b;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2.7px;
  line-height: 16px;
  margin-bottom: 10px;
}

.gridTextHeader {
  max-width: 100%;
  color: #074f8a;
  font-size: 43px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 60px;
  margin-bottom: 20px;
}

.gridTextSubtext {
  max-width: 100%;
  opacity: 0.8;
  color: #074f8a;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 20px;
  font-weight: 500;
}

.tower {
  width: 90%;
  height: 80%;
}

.placeholder {
  max-width: 100%;
  display: block;
}

.placeholderImage {
  height: 95%;
  max-width: 100%;
  border-radius: 10px;
}

.partnerLogos {
  display: flex;
  flex-direction: row;
  padding-left: 80px;
  align-items: center;
  flex-wrap: wrap;
}

.partnerLogo {
  margin: 20px 80px 20px 0;
}

/*Tech4dev logo looks weird*/
.partnerLogo:nth-of-type(4) {
  position: relative;
  top: -10px;
}

.teamSection {
  background-color: white;
}

.teamSectionContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.teamSectionText {
  width: 75%;
  color: #074f8a;
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: bold;
  line-height: 50px;
  margin-top: 70px;
  margin-bottom: 20px;
  text-align: left;
}

.teamSectionSubText {
  max-width: 70%;
  color: var(--sub-heading);
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 19px;
  letter-spacing: 0.3px;
  line-height: 32px;
  text-align: left;
}

.teamCards {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 30px;
  row-gap: 50px;
}

@media (max-width: 800px) {
  .sectionGrid {
    grid-template-columns: 1fr;
  }

  .tower {
    display: none;
  }

  .partnerLogos {
    padding-left: 50px;
  }

  .partnerLogo {
    height: 30px;
    margin: 20px 40px 20px 0;
  }

  .teamSectionText {
    font-size: 32px;
    line-height: 40px;
    width: 100%;
  }

  .teamSectionSubText {
    font-size: 18px;
    max-width: unset;
  }
}
