.getTheAppCard {
  background-color: var(--primary);
  border-radius: 10px;
  box-shadow: 0 55px 40px -41px rgba(0, 118, 197, 0.3);
  padding: 60px 16px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  color: var(--white);
  margin-bottom: 30px;
}

.subtitle {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 29px;
  color: var(--white);
  margin-bottom: 42px;
  text-align: center;
}

.appLinks {
  display: flex;
}

.appLink:not(:last-of-type) {
  margin-right: 12px;
}

@media (max-width: 900px) {
  .appLinks {
    flex-direction: column;
    justify-content: center;
  }
  .appLink {
    margin: 10px 0;
  }
}
