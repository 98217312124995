.inputGroup {
  display: flex;
  flex-direction: column;
}

.label {
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 11px;
}

.input {
  padding: 12px;
  background-color: #f4f6f8;
  border: 1px solid #f4f6f8;
  border-radius: 3px;
  font-family: var(--font-family);
  font-weight: normal;
  letter-spacing: 0;
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  transition: 0.15s ease-out;
}

.input:focus,
.inputHasContent {
  border-color: #c4cdd5;
  background-color: var(--white);
  outline: none;
  box-shadow: inset 0 0 0 1px rgba(102, 113, 123, 0.25),
    inset 0 1px 2px 0 rgba(102, 113, 123, 0.21);
}

.input::placeholder {
  opacity: 1;
  color: #919eab;
}

.inputTextarea {
  resize: none;
  min-height: 70px;
}
