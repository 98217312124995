.header {
  padding: 40px 0;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 45px;
}

.headerNav {
  display: flex;
}

.headerNavItem {
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}

.headerNavItem:hover,
.headerNavItem:focus {
  text-decoration: underline;
}

.headerNavItemActive {
  color: var(--primary);
}

.headerNavItem:not(:last-of-type) {
  margin-right: 45px;
}

@media screen and (max-width: 900px) {
  .logo {
    margin-right: 20px;
  }

  .headerContainer {
    justify-content: space-between;
  }
  .headerNavItem:not(:last-of-type) {
    margin-right: 16px;
  }
}
