.hero {
  min-height: 780px;
  height: auto;
  max-height: 100vh;
  background-image: url('../../assets/images/page-background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

.heroContainer {
  position: relative;
}

.heroContent {
  padding: 109px 0;
}

@media screen and (max-width: 900px) {
  .heroContent {
    padding: 50px 0;
  }
}

.heroImagesContainer {
  position: absolute;
  right: -125px;
  top: 0;
}

@media screen and (max-width: 900px) {
  .heroImages {
    display: none;
  }
}

.heroImages {
  position: relative;
}

.heroImage {
  position: absolute;
  max-width: 487px;
  height: auto;
  box-shadow: 0 12px 147px 0 rgba(0, 0, 0, 0.1);
}

.heroImageTop {
  z-index: 10;
  top: 0;
  right: 105px;
}

.heroImageBottom {
  z-index: 5;
  top: 76px;
  right: 0;
}

.heroTitle {
  color: var(--heading);
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
  margin-bottom: 42px;
  max-width: 590px;
}

.heroSubtitle {
  color: var(--sub-heading);
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 33px;
  opacity: 0.8;
  max-width: 544px;
}

@media screen and (max-width: 900px) {
  .heroTitle {
    font-size: 32px;
    line-height: 48px;
  }
}

.appLinks {
  display: flex;
}

.appLink {
  margin-right: 12px;
}

.pageSection {
  padding: 120px 0;
  background-color: #f9fafb;
}

.pageSectionPrimary {
  padding: 78px 0;
  background-color: var(--primary);
}

.sectionTitle {
  width: 100%;
  max-width: 569px;
  margin: 0 auto;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 56px;
  text-align: center;
  color: var(--heading);
}

.pageSectionPrimary .sectionTitle {
  text-align: left;
  color: var(--white);
}

.passionCards {
  margin: 58px auto 0;
  display: grid;
  column-gap: 44px;
  row-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(333px, 1fr));
  justify-items: center;
}

.panes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
}

.panesSpaced {
  column-gap: 93px;
}

.imagePane {
  justify-self: center;
  align-self: center;
}

.phoneImage {
  max-height: 600px;
  object-fit: contain;
  margin-bottom: 20px;
}

.appDescription {
  max-width: 460px;
}

.sectionTitleGroup {
  display: flex;
  flex-direction: column;
}

.sectionTitleLabel {
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2.7px;
  line-height: 16px;
  color: var(--white);
  text-transform: uppercase;
}

.featureList {
  margin: 60px 0 50px;
  list-style: none;
  counter-reset: feature-count;
}

.featureListItem {
  counter-increment: feature-count;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 18px;
}

.featureListItem::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '0' counter(feature-count);
  opacity: 0.07;
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 1;
  color: var(--white);
}

.sectionSpan {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 14px;
}

.featureListItemTitle {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 14px;
}

.featureListItemDescription {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  color: var(--white);
}

.featureListItem:not(:last-of-type) {
  margin-bottom: 30px;
}

.learnMoreLink {
  border: 1px solid #c4cdd5;
  border-radius: 3px;
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  color: var(--primary);
  padding: 12px 16px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  .panes {
    display: flex;
    flex-direction: column;
  }

  .imagePane {
    margin-bottom: 48px;
  }

  .phoneImage {
    max-height: 550px;
  }

  .appDescription {
    max-width: unset;
  }

  .appDescription .sectionTitle {
    max-width: unset;
  }

  .featureList {
    margin-top: 32px;
    max-width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .panesSpaced {
    display: flex;
    flex-direction: column;
  }
}

.downloadSection {
  margin-bottom: 120px;
}

.downloadImage {
  border-radius: 10px;
}

.downloadSectionText {
  align-self: center;
  max-width: 400px;
}

@media screen and (max-width: 900px) {
  .downloadImage {
    margin-bottom: 32px;
  }

  .downloadSectionText {
    margin: 0 auto;
  }
}

.partnersSectionTitle {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  color: var(--heading);
  margin: 0 0 35px;
}

.partnersList {
  display: grid;
  column-gap: 48px;
  row-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  justify-items: center;
  justify-self: center;
  margin: 0 auto;
  list-style: none;
}

.partnersListItem {
  /* filter: grayscale(1); */
  justify-self: center;
}

.partnersListItem img {
  max-width: 100%;
  width: auto;
  height: 40px;
}

.tech4devLogo {
  position: relative;
  bottom: 8px;
}

.chatCard {
  margin-bottom: 100px;
}
