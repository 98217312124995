.button {
  border: none;
  padding: 12px 20px;
  border-radius: 3px;
  background-color: var(--primary);
  cursor: pointer;
  color: var(--white);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
  transition: 0.15s ease-out;
}

.button:hover,
.button:focus {
  background-color: var(--semi-opaque-primary);
  outline: none;
}
