.pageContent {
  min-height: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 60% auto;
  column-gap: 32px;
  align-items: center;
  position: relative;
}

.logo {
  position: absolute;
  top: 5vh;
}

.pageText {
  align-self: center;
}

.pageTitle {
  color: var(--heading);
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
  margin-bottom: 42px;
  width: 590px;
  max-width: 100%;
}

.pageSubtitle {
  color: var(--sub-heading);
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 33px;
  opacity: 0.8;
  width: 544px;
  max-width: 100%;
}

.highlight {
  color: var(--highlight);
}

.chatArea {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 30px;
  right: 30px;
}

@media screen and (max-width: 900px) {
  .pageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .pageTitle {
    font-size: 32px;
    line-height: 48px;
  }

  .pageText {
    width: 100%;
  }

  .logo {
    left: 1rem;
  }

  .chatArea {
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
  }
}

.appLinks {
  display: flex;
}

.appLink {
  margin-right: 12px;
}
