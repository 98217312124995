.card {
  padding: 30px 30px 0 20px;
  height: 400px;
  font-family: var(--font-family);
  position: relative;
  width: 330px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 32px 0 #f4f6f8;
}

.cardTitle {
  max-width: 150px;
  color: #074f8a;
  font-size: 27px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33px;
  margin-bottom: 35px;
}

.cardDetails {
  opacity: 0.8;
  color: #074f8a;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 27px;
}

.cardIcon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.cardIconCorner {
  fill: #e2f1df;
}

.cardIconRight {
  fill: #fbf1cd;
}

.cardIconBottom {
  fill: #ebf5fa;
}
