.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

section {
  position: relative;
}

.sectionBgImage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.sectionBgImageBottom {
  top: unset;
  bottom: 0;
}

.top {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  height: 100vh;
  flex-direction: column;
  position: relative;
  font-family: var(--font-family);
}

.topHeader {
  color: var(--heading);
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 56px;
  max-width: 520px;
  margin-bottom: 20px;
}

.topSubtext {
  max-width: 420px;
  opacity: 0.8;
  color: var(--sub-heading);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 20px;
}

.topAppLinks {
  display: grid;
  grid-template-columns: repeat(2, 114px);
  grid-template-rows: 48px;
  grid-column-gap: 20px;
}

.topImageWrapper {
  position: absolute;
  right: 8%;
  top: 0;
  width: 426px;
  height: 85%;
}

.topImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 0 40px 40px;
}

.topImageDotsBottom {
  position: absolute;
  bottom: -59px;
  left: -51px;
  z-index: -1;
  color: #108043;
}

.topImageDotsRight {
  position: absolute;
  top: 140px;
  right: -77px;
  z-index: -1;
  color: #eec200;
}

.how {
  background-color: white;
}

.howHeader {
  margin-top: 50px;
  max-width: 465px;
  color: #074f8a;
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 56px;
  margin-bottom: 30px;
}

.howSectionWrapper {
  position: relative;
}

.howSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 100%;
  height: unset;
}

.howSectionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  align-items: center;
  padding: 100px;
  grid-column-gap: 30px;
}

.howSectionGridImage {
  width: 250px;
  object-fit: cover;
  margin: auto;
}

.howSectionDots {
  position: absolute;
  top: calc(50% - 150px);
  left: -75px;
}

.howSectionCircleDot {
  left: 0;
}

.gridText {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: var(--font-family);
}

.gridTextAppName {
  color: #51b83b;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2.7px;
  line-height: 16px;
  margin-bottom: 10px;
}

.gridTextHeader {
  max-width: 436px;
  color: #074f8a;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 56px;
  margin-bottom: 20px;
}

.gridTextSubtext {
  max-width: 371px;
  opacity: 0.8;
  color: #074f8a;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
}

.getTheApp {
  padding: 80px 10% 120px;
  background-color: white;
}

@media (max-width: 1100px) {
  .topHeader {
    max-width: 430px;
  }
  .topImageWrapper {
    width: 330px;
  }
  .topImageDotsBottom,
  .topImageDotsRight {
    display: none;
  }
}

@media (max-width: 900px) {
  .topImageWrapper {
    display: none;
  }
  .howSectionWrapper:nth-of-type(2n) {
    background-image: linear-gradient(#e1f4fe, rgba(225, 244, 254, 0.5));
  }
  .howSectionWrapper:nth-of-type(2n) .sectionBgImage {
    display: none;
  }
}

@media (max-width: 650px) {
  .top {
    height: 80vh;
  }
  .topHeader {
    font-size: 36px;
    line-height: 48px;
  }
  .topSubtext {
    font-size: 14px;
  }
  .howSectionGrid {
    grid-template-columns: 1fr;
    padding: 30px 0;
    grid-row-gap: 30px;
  }
  .howSectionDots,
  .howSectionCircleDot {
    display: none;
  }
  .howSectionGridImage {
    width: 200px;
  }
  .howSectionWrapper:nth-of-type(odd) .howSectionGridImage {
    grid-row: 2;
  }
  .howHeader {
    font-size: 36px;
    line-height: 48px;
    text-align: center;
  }
  .gridText {
    align-items: center;
  }
  .gridTextHeader {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
  .gridTextSubtext {
    font-size: 14px;
    line-height: 20px;
  }
}
