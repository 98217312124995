.cardWrapper {
  max-width: 250px;
  width: 100%;
  margin: auto;
}

.card {
  height: 240px;
  max-width: 250px;
  border-radius: 10px;
  background-color: #d8d8d8;
}

.memberName {
  color: var(--primary);
  font-size: 16px;
  font-weight: bold;
  margin-top: 25px;
  text-align: center;
}

.designation {
  color: var(--sub-heading);
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}

.cardImage {
  width: 100%;
  border-radius: 10px;
}
